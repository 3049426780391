<template>
  <div class="">
    <div v-if="ready">
      <div class="p-3 pb-2 border-bottom border-gray">
        <div class="text-light-ipepp font-weight-bold">
          {{ reportTitle(nationalPlan, province) }}
        </div>
        <div
          class="justify-content-between mt-3 align-items-baseline"
          :class="{ 'd-flex ': reportStatus.remaining_days != 0 }"
        >
          <div class="">
            กำหนดส่งแผนภายใน {{ reportStatus.end_date | thai_date }} เหลือเวลา
          </div>
          <div
            v-if="reportStatus.remaining_days > 0"
            class="font-weight-bold text-h5"
            :class="{
              'text-yellow':
                reportStatus.remaining_days < 5 &&
                reportStatus.remaining_days >= 0,
              'text-danger': reportStatus.remaining_days < 0,
            }"
          >
            {{ reportStatus.remaining_days }} วัน
          </div>
          <div class="font-weight-bold text-danger" v-else-if="reportStatus.remaining_days < 0">
            เลยกำหนด {{ reportStatus.remaining_days | number_abs }} วัน
          </div>
          <div v-else class="font-weight-bold text-h5 text-danger">
            น้อยกว่า 1 วัน
          </div>
        </div>
      </div>
      <div class="p-3 pb-2 border-bottom border-gray">
        <div class="d-flex justify-content-between">
          <div>สร้างขึ้นเมื่อ</div>
          <div class="helper-color">
            {{ reportStatus.created_at | thai_date }}
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div>แก้ไขล่าสุดเมื่อ</div>
          <div class="helper-color">
            {{ reportStatus.updated_at | thai_date }}
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div>สถานะ</div>
          <div class="font-weight-bold">
            {{ reportStatus.version | report_status }}
          </div>
        </div>
      </div>
      <div class="pb-2 px-3">
        <div
          class="row align-items-center my-2"
          v-for="topic in reportStatus.topics"
          v-bind:key="topic.id"
        >
          <div class="col-7">{{ topic.title }}</div>
          <report-progress-bar class="col-5" :progress="topic.progress">
          </report-progress-bar>
        </div>
      </div>
    </div>
    <div class="p-3 pb-2" v-if="!ready">
      <div class="text-light-ipepp font-weight-bold">
        แผนปฏิบัติราชการด้านพลังงานของจังหวัด พ.ศ.
        {{ latestNationalPlanYearRange }}
      </div>
      <vue-element-loading
        :active="!ready"
        spinner="spinner"
        color="#ED110E"
        class="mt-4 position-relative"
        background-color="transparent"
      />
    </div>
  </div>
</template>

<script>
import ReportProgressBar from '@/components/ReportProgressBar.vue';
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

export default {
  name: 'ProvinceReportStatus',

  components: {
    ReportProgressBar,
  },

  props: {
    nationalPlan: {
      type: Object,
      required: true,
    },
    province: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      reportStatus: null,
      ready: false,
    };
  },

  created() {
    this.init();
  },

  computed: {
    ...mapGetters(['latestNationalPlanYearRange', 'reportTitle']),
  },

  methods: {
    ...mapActions('province', ['fetchProvinceReportStatus']),
    init() {
      this.fetchProvinceReportStatus({
        nationalPlan: this.nationalPlan,
        province: this.province,
      }).then((data) => {
        this.reportStatus = data;
        this.ready = true;
      });
    },
  },
};
</script>

<style scoped></style>
