<template>
  <section class="container-fluid" id="main_content">
    <div class="row">
      <div class="col-md-8 mt-4">
        <div class="d-flex justify-content-between">
          <h4 class="font-weight-bold text-left d-flex">
            <span class="mr-3"
              >ภาพรวมจังหวัด{{ currentProvince | province_name_locale }}</span
            >
          </h4>
          <a
            class="btn btn-outline-secondary text-ipepp pointer"
            @click="$router.go(-1)"
          >
            <eva-icon name="arrow-ios-back"></eva-icon> ย้อนกลับ
          </a>
        </div>
        <div class="box box-responsive my-4" v-if="ready">
          <div class="box-header pt-2">
            <div class="d-flex">
              <div class="mr-auto py-2">แผนปฏิบัติราชการ</div>
              <a
                class="link-unstyle mx-3 text-ipepp p-2 link-hover"
                :href="report.pdf_url"
                target="_blank"
              >
                EXPORT PDF <eva-icon name="file"></eva-icon>
              </a>
            </div>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col-12">
                <pdf-viewer :source="report.pdf_url"></pdf-viewer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 side-shadow peno-overview-side px-0">
        <affix
          class="mt-2"
          relative-element-selector="#main_content"
          :offset="{ top: 0, bottom: 0 }"
          :scrollAffix="false"
        >
          <province-report-status
            class=""
            :nationalPlan="nationalPlan"
            :province="currentProvince"
          >
          </province-report-status>
        </affix>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import PdfViewer from '@/components/PdfViewer.vue';
import ProvinceReportStatus from '@/components/ProvinceReportStatus.vue';
import { find } from 'lodash';

export default {
  name: 'OtherProvinceReport',

  components: {
    ProvinceReportStatus,
    PdfViewer,
  },

  data() {
    return {
      report: null,
      ready: false,
    };
  },

  created() {
    this.init();
  },

  computed: {
    ...mapState(['meta', 'user', 'provinces']),
    ...mapGetters(['latestNationalPlan']),
    currentProvince() {
      return find(this.provinces, { iso3166_2: this.$route.params.iso3166_2 });
    },
    nationalPlan() {
      return this.latestNationalPlan;
    },
  },

  methods: {
    ...mapActions('province', ['fetchProvinceReport']),
    init() {
      this.fetchProvinceReport(this.currentProvince).then((data) => {
        this.report = data;
        this.ready = true;
      });
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped></style>
